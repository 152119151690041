import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias20 from "../../../../static/img/blog/noticias-20-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Dos prácticas de relación con inversionistas que tomaron mayor relevancia debido al COVID-19 '>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias20}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                20 Septiembre. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Ricardo Ávila
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>Dos prácticas de relación con inversionistas que tomaron mayor relevancia debido al COVID-19 </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>Como respuesta a las limitantes que resultaron de la pandemia de COVID-19, las compañías se
                        vieron en la necesidad de implementar diversos cambios en el área de relación con inversionistas
                        para adaptarse a la nueva realidad y mantenerse a la vanguardia. Si bien, en un principio la
                        mayoría de las emisoras consideraba que dichos ajustes serían temporales, a medida que la
                        pandemia evoluciona, cada vez más IROs, analistas e inversionistas, opinan que varias prácticas
                        permanecerán vigentes después de que termine la contingencia sanitaria, dentro de las que destacan:</p>

                        <p><em><b>•	Conferencias Virtuales. </b></em> A pesar de la adversidad, la necesidad de juntas,
                        reuniones y asambleas persistió, por lo que una de las prácticas que resultó fundamental, al
                        establecerse los límites de convivencia por restricciones sanitarias, fue la implementación de
                        las reuniones virtuales, cuya practicidad y eficiencia, permitieron generar importantes ahorros
                        en gastos y mantener una buena comunicación con los inversionistas.</p>

                        <p><em><b>•	Enfoque de Inversión.</b></em> La contingencia sanitaria vino a acelerar de forma
                        importante algunas tendencias que venían tomando fuerza de manera paulatina, como es el caso del
                        <em>e-commerce</em> y la adopción de las mejores prácticas ASG. Lo anterior, debido a que las empresas
                        que contaban con canales de venta digitales y sólidas prácticas ASG, demostraron ser las mejor
                        posicionadas para darle continuidad a la operación durante la coyuntura económica retadora y con
                        alto nivel de incertidumbre que se originó. Por esta razón, actualmente un número incremental de
                        inversionistas y analistas consideran con mayor peso estos aspectos al momento de tomar una
                        decisión de inversión y de aplicar sus modelos de valuación; tal que, informar de forma oportuna
                        y efectiva la estrategia de las emisoras en estos rubros se vuelve fundamental para la retención
                        y atracción de inversionistas (siendo el informe de sostenibilidad una importante herramienta para ello).</p>

                        <p>Si bien, la implementación y tendencia de permanencia de las prácticas anteriormente mencionadas
                        contribuyeron a que la mayor parte de las empresas se adaptaran a este nuevo contexto (obteniendo
                          resultados mejores a los esperados en el transcurso de la pandemia), es claro que no son las mismas
                          circunstancias para todas las compañías. Por ello es importante que se realice una evaluación
                          exhaustiva del estatus de la empresa, para estructurar un plan de acción “a la medida”, que le
                          permita alcanzar una comunicación más asertiva con sus inversionistas y demás grupos de interés,
                          apoyándose para esto de su departamento de RI y/o asesor especializado en RI.</p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Ward-Brennan, M. (2021). Covid-19 will lead to permanent change in IR working practices, finds research. Julio 7, 2021.<a href="https://www.irmagazine.com/case-studies/covid-19-will-lead-permanent-change-ir-working-practices-finds-research" target="_blank">IR MAGAZINE</a>.</li>
                            <li>IR MAGAZINE. (2021) Covid-19 Update: How investor relations has been affected by the Covid-19 pandemic and what this means for the post-pandemic IR landscape. Julio, 2021. <a href="https://content.irmagazine.com/story/sample-covid-19-update-2021/page/1" target="_blank">IR MAGAZINE</a>.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
